

h1 {
  color: white;
  font-family: 'Dela Gothic One', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.dela {
  font-family: 'Dela Gothic One', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.noto {
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.header-container {
  height: 40rem;
  color: white;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  .center {
    max-width: 50vw;
    p, h1 {
      text-shadow: 1px 1px 20px #5a3a11;
    }
  }
}

.card {
  color: #2c3034;
}

img.card-img-top {
  height: 25rem;
  /* width: -webkit-fill-available; */
  object-fit: cover;
}

.ch-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #DF7C31;
  transition: .3s;

  .segment-top {
    display: flex;
    img {
      height: 15rem;
      max-width: 10rem;
      object-fit: cover;
    }
  }

  .segment-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    span {
      color: white;
      font-weight: bolder;
    }
  }

  &:hover {
    border: #DF7C31 5px;
    background: #2c3034;
  }

  @media screen and (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    font-size: 0.8em;
  }
}

.chapter-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;

  a {
    color: #ffffff;
    font-weight: bolder;
    font-size: 0.8em;
  }
}