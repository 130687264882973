/*Vertical Sliding*/
.slidingVertical{
  display: block;
  margin-top: 1em;
}
.slidingVertical .main{
  animation: main .3s ease-in-out;
  animation-delay: 4s;
  opacity: 1;
  animation-fill-mode: forwards;
  position: absolute;
}
.slidingVertical .sub{
  animation: sub .3s ease-in-out;
  animation-delay: 4.4s;
  opacity: 0;
  animation-fill-mode: forwards;
  position: absolute;

}

/*topToBottom Animation*/
@keyframes main{
  0% { opacity: 1; }
  100% { opacity: 0}
}

@keyframes sub{
  0% { opacity: 0;}
  100% { opacity: 1;}
}
