@import "src/utils/animate";

.header {
  background: #DF7C31;
  height: 3rem;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: .5em;
  border-radius: 5px;

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .header-item {
    color: #4c4c4c;
    font-weight: bolder;
    font-size: 1.3em;
  }

  .header-sub-item {
    color: #ffffff;
    font-weight: bolder;
    font-size: 0.8em;
  }

  @media screen and (max-width: 600px) {
    height: 5rem;
    .type-head {
      display: none;
    }

    .flex-row {
      flex-direction: column!important;
    }

    .header-sub-item {
      margin-top: 1em;
    }
  }
}

.app {
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  .title {
    font-family: 'Dela Gothic One', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: white;
  }

  a:hover {
    text-decoration: none;
  }
  a {
    text-decoration: none;
  }
}