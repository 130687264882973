@import "src/utils/animate";

.viewer {
  padding-top: 2em;
  display: flex;

  .control-panel {
    padding: .5em;
    border-radius: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    .segment {
      display: flex;
    }

    @media screen and (max-width: 600px) {
      font-size: 0.8em;
      flex-direction: column;

      .segment {
        padding-top: 1em;
      }
    }
  }

  .loading {
    z-index: 100;
  }

  .image-box {
    display: flex;
    justify-content: center;
    align-content: center;

    img {
      border-radius: 5px;
    }
  }
}

.pattern-a {
  background-color: #e5e5f7;
  background-image:  linear-gradient(135deg, #444cf7 25%, transparent 25%), linear-gradient(225deg, #444cf7 25%, transparent 25%), linear-gradient(45deg, #444cf7 25%, transparent 25%), linear-gradient(315deg, #444cf7 25%, #e5e5f7 25%);
  background-position:  10px 0, 10px 0, 0 0, 0 0;
  background-size: 10px 10px;
  background-repeat: repeat;
}

.pattern-b {
  background-color: #e5e5f7;
  background-image:  linear-gradient(#ffffff 1px, transparent 1px), linear-gradient(to right, #ffffff 1px, #c033e0 1px);
  background-size: 20px 20px;
}